import axios from 'axios'

// 请求接口的域名
const BASE_URL = 'http://101.132.67.68:8003'

// 请求的超时
const DEFAULT_TIMEOUT = 100000

/**
 * 数据请求
 * @param params 参数
 * @param method 请求类型 'GET' 'POST'
 * @return Promise
 */
export const request = async (params, method) => {
  let data = 'data'
  let url = params.url
  if (method.toLocaleLowerCase() === 'get') {
    data = 'params'
    // url = toggleUrlParams(url)
  }
  params.headers = params.headers || {};
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: BASE_URL + url,
      [data]: params.data,
      headers: {
        ...params.headers
      },
      timeout: params.timeout || DEFAULT_TIMEOUT
    }).then((res) => {
      resolve(res.data)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * GET 请求
 * @param url 请求地址
 * @param data 请求参数
 * @param params 额外参数 例：hearders: {}
 */
export const GET = async (url, data, params) => {
  return request({
    url,
    data,
    ...(params || {})
  }, 'GET')
}

/**
 * POST 请求
 * @param url 请求地址
 * @param data 请求参数
 * @param params 额外参数 例：hearders: {}
 */
export const POST = async (url, data, params) => {
  return request({
    url,
    data,
    ...(params || {})
  }, 'POST')
}
