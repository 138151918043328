<template>
  <div class="nav-bar-container">
    <!--左侧-->
    <template>
      <img v-if="isClose&&!isCloseRight" class="icon-box" :src="$imgHost + '/static2/icons/icon_back@2x.png'" @click="onBack" />
      <div v-else></div>
    </template>
    <!--右侧-->
    <div v-if="isClose&&isCloseRight">
      <img class="icon-box mute" :src="`${$imgHost}/static2/icons/icon_music${isMute ? '_mute' : ''}@2x.png`" @click="onMute" />
      <img class="icon-box" :src="$imgHost + '/static2/icons/icon_close@2x.png'" @click="onBack" />
    </div>
    <div v-else>
      <img class="icon-box mute" :src="`${$imgHost}/static2/icons/icon_music${isMute ? '_mute' : ''}@2x.png`" @click="onMute" />
      <img v-if="isMenu" class="icon-box" :src="$imgHost + '/static2/icons/icon_close@2x.png'" @click="isMenu = false" />
      <img v-else class="icon-box" :src="$imgHost + '/static2/icons/icon_menu@2x.png'" @click="isMenu = true" />
    </div>
    <!--菜单弹窗-->
    <div v-if="isMenu" class="cover-box"></div>
    <el-drawer
        :visible.sync="isMenu"
        direction="rtl"
        size="560rem"
        :modal="false"
        :modal-append-to-body="false"
        :withHeader="false"
    >
      <div class="menu-list-container">
        <img class="icon-box" :src="$imgHost + '/static2/icons/icon_close@2x.png'" @click="isMenu = false" />
        <div class="line-box l-center"></div>
        <div class="menu-list-scroll">
          <template v-for="(item,index) in menuList">
            <div class="menu-list-box" :style="{ marginBottom: item.list||index===menuList.length-1 ? '1rem' : '20rem' }" @click="onPage(item.url)">
              <div v-text="item.name"></div>
              <div class="btn-box">
                <img :src="$imgHost + '/static2/icons/arrow@2x.png'" />
              </div>
            </div>
            <div
                v-if="item.list"
                class="menu-item-box"
                v-for="(items,index2) in item.list"
                :style="{ marginBottom: index2===item.list.length-1 ? '20rem' : '1rem' }"
                @click="onPage(items.url)"
            >
              <div class="menu-item-box__point"></div>
              <div class="menu-item-box__name" v-text="items.name"></div>
              <img :src="$imgHost + '/static2/icons/icon_arrow_xs@2x.png'" />
            </div>
          </template>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
/**
 * NavBar 导航栏
 * @property {Boolean} isClose 是否显示返回按钮
 */
export default {
  name: "NavBar",
  props: {
    isClose: {
      type: Boolean,
      default: false
    },
    isCloseRight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMenu: false,
      isMute: false,
      menuList: [
        { name: '前 言', url: '/preface'},
        { name: '主 页', url: '/home'},
        { name: '蔚蓝星球 · 万物生灵', url: '/earth'},
        { name: '人类活动 · 蝴蝶效应', url: '/human',
          list: [
            { name: '灭绝动物掠影', url: '/human/animal' },
            { name: '最大的遗憾是我从未真正了解你', url: '/human?k=1' },
            { name: '失去你是我无法承受的损失', url: '/human?k=2' }
          ]
        },
        { name: '国门卫士 · 守护生态', url: '/guard',
          list: [
            { name: '健全机制完善法制', url: '/guard?k=1' },
            { name: '监测预警严格审批', url: '/guard?k=2' },
            { name: '加强检疫提升保障', url: '/guard?k=3' },
            { name: '严厉打击违法违规', url: '/guard?k=4' },
            { name: '多元共治形成合力', url: '/guard?k=5' }
          ]
        },
        { name: '绿水青山 · 美丽中国', url: '/landscape'},
        { name: '结 语', url: '/home?current=4'},
        { name: '我是濒危动物守护人', url: '/home?current=5'}
      ]
    }
  },
  methods: {
    onBack() {
      this.isMenu = false;
      this.$router.back();
    },
    onMute() {
      this.isMute = !this.isMute
      this.$store.commit('checkMute', this.isMute);
      if (this.isMute) {
        this.$music.pause();
      } else {
        this.$music.play();
      }
    },
    onPage(url) {
      this.$router.push(url);
      this.isMenu = false;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-drawer {
  background: none !important;
}

.nav-bar-container {
  height: 100rem;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40rem;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .icon-box {
    width: 40rem;
    height: 40rem;
    cursor: pointer;
    z-index: 11;

    &:hover {
      background: #0850DD;
      border: 1px solid #FFFFFF;
      border-radius: 50%;
      box-sizing: border-box;
    }

    &.mute {
      margin-right: 14rem;
    }
  }
}

.cover-box {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.60);
  backdrop-filter: blur(20rem);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  margin: 0;
}

.menu-list-container {
  width: 560rem;
  height: 100vh;
  //font-family: PingFangSC-Light;
  font-size: 14rem;
  color: #FFFFFF;
  letter-spacing: 0.88rem;
  line-height: 24rem;
  font-weight: 200;
  background: rgba(0,0,0,0.80);
  backdrop-filter: blur(20rem);
  float: right;

  .icon-box {
    width: 40rem;
    height: 40rem;
    cursor: pointer;
    position: fixed;
    top: 30rem;
    right: 40rem;
    z-index: 11;
  }

  .line-box {
    width: 480rem;
    height: 1rem;
    background: #FFFFFF;
    position: absolute;
    top: 84rem;
  }

  .menu-list-scroll {
    width: 100%;
    height: calc(100% - 125rem);
    overflow-y: scroll;
    padding: 0 40rem;
    box-sizing: border-box;
    position: absolute;
    top: 105rem;
    left: 0;

    .menu-list-box {
      width: 100%;
      height: 60rem;
      //font-family: PingFangSC-Medium;
      font-size: 16rem;
      color: #FFFFFF;
      letter-spacing: 1rem;
      line-height: 20rem;
      font-weight: 500;
      background: rgba(255,255,255,0.10);
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 16rem;
      box-sizing: border-box;

      .btn-box {
        width: 56rem;
        height: 28rem;
        background: #0850DD;
        text-align: center;
        line-height: 28rem;

        img {
          width: 24rem;
          height: 9rem;
        }
      }
    }

    .menu-item-box {
      width: 100%;
      height: 44rem;
      background: rgba(255,255,255,0.10);
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 28rem;
      padding-right: 16rem;
      box-sizing: border-box;
      position: relative;

      &__point {
        width: 2rem;
        height: 2rem;
        background: #FFFFFF;
        position: absolute;
        top: 21rem;
        left: 16rem;
      }

      img {
        width: 12rem;
        height: 12rem;
      }
    }
  }
}
</style>
