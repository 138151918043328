<template>
  <div id="app">
    <nav-bar v-show="!$route.meta.isHideBar" :is-close="$route.meta.isBack" :is-close-right="$route.meta.isBackRight"></nav-bar>
    <router-view/>
  </div>
</template>

<script type="text/javascript" src="https://res2.wx.qq.com/open/js/jweixin-1.6.0.js"></script>
<script>
import NavBar from "@/components/nav-bar";
import {getConfig} from "@/api";
export default {
  components: {NavBar},
  data() {
    return {
    }
  },
  created() {
    this.init();
  },
  mounted() {
    if (this.$route.path === '/') return false;
    this.isMobile();
  },
  destroyed() {
    this.$music.pause();
  },
  watch:{
    $route: {
      handler(val){
        // 如果是视频页（首页）
        if (val.name === 'index')  {
          this.$music.pause();
        } else {
          // 如果正在播放中
          if (!this.$music.paused) return false;
          // 当前是未静音状态
          if (!this.$store.state.isMute) {
            this.$music.src = this.$imgHost + '/static/audios/bgm.mp3';
            this.$music.play();
          }
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    init() {
      const wx = require('jweixin-module');
      let url = location.href.split('#')[0];
      getConfig({
        url: url
      }).then(res =>{
        if (res.code === 200) {
          let configInfo = res.data;
          wx.config({
            debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            appId: configInfo.appId, // 必填，公众号的唯一标识
            timestamp: configInfo.timestamp, // 必填，生成签名的时间戳
            nonceStr: configInfo.nonceStr, // 必填，生成签名的随机串
            signature: configInfo.signature, // 必填，签名
            jsApiList: [
              "onMenuShareAppMessage",
              'updateAppMessageShareData',
              "onMenuShareTimeline",
              'updateTimelineShareData'
            ]
          });
          wx.ready(function() { //需在用户可能点击分享按钮前就先调用
            // 配置自定义分享内容
            let options = {
              title: '国门生物安全之保护生物多样性篇', // 分享标题
              desc: '中国海关博物馆', // 分享描述
              link: 'http://wmkj.hashtreas.cn', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
              imgUrl: 'https://weimai-customs.oss-cn-hangzhou.aliyuncs.com/static/images/share.jpg', // 分享图标
              success: function() {
                console.log('设置成功')
              },
              cancel: function() {}
            }
            // 分享给朋友
            if (wx.onMenuShareAppMessage) {
              wx.onMenuShareAppMessage(options)
            } else {
              wx.updateAppMessageShareData(options)
            }
            // 分享到朋友圈
            if (wx.onMenuShareTimeline) {
              wx.onMenuShareTimeline(options)
            } else {
              wx.updateTimelineShareData(options)
            }
          });
          wx.error(function(res) {
            console.log('wx.----err---------', res)
            // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
          });
        }
      })
    },
    isMobile() {
      let windowWidth = document.documentElement.clientWidth;
      if (windowWidth <= 768) {
        window.location.href = 'http://wmkj.hashtreas.cn/hg/index.html#/pages/preface/preface'
      }
      // window.onresize = () => {
      //   return (() => {
      //     let windowWidth = document.documentElement.clientWidth;
      //     if (windowWidth <= 768) {
      //       window.location.href = 'http://wmkj.hashtreas.cn/hg/index.html#/pages/preface/preface'
      //     }
      //   })()
      // }
      setTimeout(() => {
        if (!this.$store.state.isMute) {
          this.$music.src = this.$imgHost + '/static/audios/bgm.mp3';
          this.$music.play();
        }
      }, 100)
    }
  }
}
</script>

<style lang="scss">
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}
</style>
