import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        isMute: false   // 是否静音
    },
    getters:{
    },
    actions:{
    },
    mutations:{
        checkMute(state, isMute) {
            state.isMute = isMute;
        }
    }
})

export default store
