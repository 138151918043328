import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import '@/utils/rem'
import '@/assets/styles/global.scss'
import '@/assets/styles/animate.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.prototype.$imgHost = 'https://weimai-customs.oss-cn-hangzhou.aliyuncs.com'

import store from './store'
Vue.prototype.$store = store

Vue.prototype.$music = new Audio();

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
