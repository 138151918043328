import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
    meta: {
      isHideBar: true
    }
  },
  {
    path: '/preface',
    name: '前言',
    component: () => import('@/views/preface/preface.vue')
  },
  {
    path: '/home',
    name: '主页',
    component: () => import('@/views/home/home.vue')
  },
  {
    path: '/earth',
    name: '蔚蓝星球·万物生灵',
    component: () => import('@/views/earth/earth.vue'),
    meta: {
      isBack: true,
      isBackRight: true
    }
  },
  {
    path: '/human',
    name: '人类活动·蝴蝶效应',
    component: () => import('@/views/human/human.vue'),
    meta: {
      isBack: true
    }
  },
  {
    path: '/human/animal',
    name: '灭绝动物掠影',
    component: () => import('@/views/human/animal.vue'),
    meta: {
      isBack: true,
      isBackRight: true
    }
  },
  {
    path: '/guard',
    name: '国门卫士·守护生态',
    component: () => import('@/views/guard/guard.vue'),
    meta: {
      isBack: true
    }
  },
  {
    path: '/landscape',
    name: '绿水青山·美丽中国',
    component: () => import('@/views/landscape/landscape.vue'),
    meta: {
      isBack: true,
      isBackRight: true
    }
  }
]

const router = new VueRouter({
  routes
})

// 解决 Vue 重复点击相同路由
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
